"use client"

import { MapPin, Phone } from "lucide-react"
import "../TextSlider/TextSlider.css"

export default function TextSlider() {
    return (
        <div className="text-slider-container">
            <div className="slider-content">
                <div className="sliding-text">
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                    <p>
                        ✨❄️
                        Now offering Medical Equipment Rentals 🛋️
                        ❄️ Quick Delivery & Exceptional Support 🚑
                        Rent Wheelchairs, Knee Scooters, Hospital Beds, and More ✨
                        📞 Call Us at 760 552 4075 for Delivery or Pickup Service 🚚
                    </p>
                </div>
            </div>

            <div className="slider-buttons">
                <a
                    href="https://www.google.com/maps/place/On+The+Go+Medical+Supply/@34.467895,-117.29316,17z/data=!4m6!3m5!1s0x80c367fa97ed692f:0x7cdfdbce6886be18!8m2!3d34.467895!4d-117.29316!16s%2Fg%2F11g03z9wk6?hl=en&entry=ttu&g_ep=EgoyMDI1MDIwOS4wIKXMDSoASAFQAw%3D%3D"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="direction-btn"
                >
                    {/* <button className="direction-btn"> */}
                        <MapPin className="btn-icon" />
                        Get Directions
                    {/* </button> */}
                </a>

                <button className="phone-btn">
                    <Phone className="btn-icon" />
                    760-552-4075
                </button>
            </div>
        </div>
    )
}

